export default {
	mounted() {
		this.changeDocumentTitle();
	},
	updated() {
		this.changeDocumentTitle();
	},
	beforeDestroy() {
		document.title = `${process.env.VUE_APP_TITLE}`;
	},
	methods: {
		translateList() {
			const translated = [];
			if (this.list[this.list.length - 1].path !== process.env.VUE_APP_HOME_PATH) {
				this.list.forEach((item, key) => {
					if (item.path !== this.list[this.list.length - 1].path) {
						if ((item.path !== this.list[key - (key === 0 ? 0 : 1)].path) || key === 0) {
							let { path } = item;
							if (path.trim() === '') {
								path = process.env.VUE_APP_HOME_PATH;
							}
							translated.push({ path, meta: { label: this.getDocumentTitle(item.name) } });
						}
					}
				});
			}

			translated.push({ meta: { label: this.getDocumentTitle(this.list[this.list.length - 1].name) } });
			return translated;
		},
		getTitle(item, params) {
			let { name } = this.$route;
			if (typeof item !== 'undefined') {
				name = item;
			}

			const title = this.translate(name + process.env.VUE_APP_HEADER_POSTFIX, params);
			return title;
		},
		getDocumentTitle(item) {
			let { name } = this.$route;
			if (typeof item !== 'undefined') {
				name = item;
			}

			const title = this.translate(name + process.env.VUE_APP_TITLE_POSTFIX);
			return title;
		},
		changeDocumentTitle() {
			document.title = `${this.getDocumentTitle()} - ${process.env.VUE_APP_TITLE}`;
		},
	},
};
