<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		class="border-secondary mb-2 px-3 pt-2 pb-0"
		style="border: 1px solid black; border-radius: 5px">
		<p class="h3 text-dark-blue">
			{{ translate('upgrade_to') }} <strong class="text-success"> {{ translate(`${packageCode}${upgradeOption.suffix}`) }} </strong>
		</p>
		<div class="row mt-3">
			<div class="col pr-1">
				{{ translate(`${packageCode}${upgradeOption.suffix}_benefit`) }}
			</div>
			<div class="col col-auto">
				<switch-toggle
					id="upgradeSelected"
					v-model="upgradeSelected"
					name="upgradeSelected"
					variant="success"
					class="mr-2"
					style="vertical-align: top"
					no-margin
					pill />
			</div>
		</div>
		<div class="row my-3">
			<div
				class="col-auto"
				v-html="translate('extra_price', {extra_price: upgradeOption.extra_price})" />
			<div
				:class="['xs'].includes(windowWidth) ? 'col-12 mt-2' : 'text-right'"
				class="col">
				<span
					v-if="upgradeSelected"
					class="flex-1 text-light-green h4 text-uppercase">
					{{ translate('upgrade_package_selected') }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { Purchase } from '@/translations';
import EventBus from '@/util/eventBus';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import SwitchToggle from '@/components/Switch/index.vue';

export default {
	name: 'UpgradePackageOption',
	components: { SwitchToggle },
	messages: [Purchase],
	mixins: [WindowSizes, ConfigurableProducts],
	props: {
		packageCode: {
			type: String,
			required: true,
			default: '',
		},
		upgradeOption: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			upgradeSelected: false,
		};
	},
	watch: {
		upgradeSelected() {
			this.toggleSelected();
		},
	},
	methods: {
		toggleSelected() {
			if (this.upgradeSelected) {
				EventBus.$emit('upgradePackageSelected', this.upgradeOption);
			} else {
				EventBus.$emit('upgradePackageSelected', null);
			}
		},
	},
};
</script>
