import {
	GET_DISTRIBUTOR_RANKS, GET_PRODUCTS_PACKAGES, GET_AVAILABLE_PACKAGES, GET_DISTRIBUTOR_ELITE_LEVELS,
	GET_REGISTER_PACKS, GET_TRANSFER_FEE_PACKS, PACKS_CALCULATION,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class GeneralInformation {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
	}

	clear() {
		this.data.clear();
	}

	getRanks() {
		const { method, endpoint } = GET_DISTRIBUTOR_RANKS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getEliteLevels() {
		const { method, endpoint } = GET_DISTRIBUTOR_ELITE_LEVELS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPackages(options = {}) {
		const { method, endpoint } = GET_PRODUCTS_PACKAGES;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAvailablePackages(userId) {
		if (typeof userId === 'undefined') {
			return Promise.reject(new Error('user_id_required'));
		}
		const { method, endpoint } = GET_AVAILABLE_PACKAGES;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getRegisterPacks(countryCode, flow, sponsor) {
		const { method, endpoint } = GET_REGISTER_PACKS;
		const options = { country: countryCode, flow, sponsor };
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTransferFeePacks() {
		const { method, endpoint } = GET_TRANSFER_FEE_PACKS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	packsCalculation(payload) {
		const { method, endpoint } = PACKS_CALCULATION;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default GeneralInformation;
