import { API_URL, /* SKIP_COUNTRIES, */ SKIP_TOKEN } from '@/settings/Captcha';
import Country from '@/util/Country';

export default {
	data() {
		return {
			apiUrl: API_URL,
			captchaLoaded: false,
			ipCountry: null,
		};
	},
	created() {
		// Get country by IP on component creation
		const countries = new Country();
		countries.setCountryByIP()
			.then((countryCode) => {
				this.ipCountry = countryCode;
				this.handleCaptchaScript();
			})
			.catch(() => {
				this.ipCountry = null;
				this.handleCaptchaScript();
			});
	},
	watch: {
		country: {
			handler() {
				this.handleCaptchaScript();
			},
		},
	},
	beforeDestroy() {
		this.removeCaptchaScript();
	},
	methods: {
		shouldSkipCaptcha() {
			// TODO: Remove conditional skipping of captcha once we migrate to hCaptcha. Skipping all captcha checks for now (2024-11-10)
			// return SKIP_COUNTRIES.includes(this.country) || SKIP_COUNTRIES.includes(this.ipCountry);
			return true;
		},
		handleCaptchaScript() {
			if (this.shouldSkipCaptcha()) {
				this.removeCaptchaScript();
			} else if (!this.captchaLoaded) {
				this.loadCaptchaScript();
			}
		},
		loadCaptchaScript() {
			const recaptchaScript = document.createElement('script');
			recaptchaScript.setAttribute('src', this.apiUrl + process.env.VUE_APP_RECAPTCHA_SITE_KEY);
			recaptchaScript.setAttribute('id', 'google_captcha');
			document.body.appendChild(recaptchaScript);
			this.captchaLoaded = true;
		},
		removeCaptchaScript() {
			const recaptchaScript = document.getElementById('google_captcha');
			if (recaptchaScript) {
				recaptchaScript.parentNode.removeChild(recaptchaScript);
			}

			const recaptchaBadge = document.getElementsByClassName('grecaptcha-badge');
			if (recaptchaBadge) {
				for (let i = 0; i < recaptchaBadge.length; i += 1) {
					recaptchaBadge[i].parentNode.removeChild(recaptchaBadge[i]);
				}
			}
			this.captchaLoaded = false;
		},
		getCaptchaToken(action) {
			if (this.shouldSkipCaptcha()) {
				return Promise.resolve(SKIP_TOKEN);
			}

			return new Promise((resolve, reject) => {
				try {
					if (typeof window.grecaptcha !== 'undefined') {
						window.grecaptcha.ready(() => {
							window.grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action }).then((token) => {
								resolve(token);
							}, () => {
								resolve('recaptcha_error');
							});
						});
					} else {
						resolve('recaptcha_error');
					}
				} catch (err) {
					reject(err);
				}
			});
		},
	},
};
