<template>
	<b-modal
		ref="extraProductsModal"
		scrollable
		no-close-on-backdrop
		header-class="mx-auto w-100 bg-dark text-white"
		:size="['xs', 'sm'].includes(windowWidth) ? '' : 'md'"
		:body-class="['xs'].includes(windowWidth) ? 'p-2' : ''"
		centered
		@hide="$emit('close')">
		<template v-slot:modal-header>
			<h5
				v-if="sectionsImport.length > 0"
				class="modal-title text-white">
				{{ isFreeModal ? translate('title_free') : translate('title') }}
			</h5>
			<h5
				v-else
				class="modal-title text-white">
				{{ translate('upgrade_package_title') }}
			</h5>
		</template>
		<div
			v-if="upgradeOptions.length > 0"
			class="row no-gutters justify-content-center">
			<div
				v-for="(upgradeOption, index) in upgradeOptions"
				:key="index"
				class="col-12 px-2">
				<upgrade-package-option
					:package-code="packageCode"
					:upgrade-option="upgradeOption" />
			</div>
		</div>
		<div
			v-if="sectionsImport.length > 0"
			class="row">
			<p
				class="mb-2 text-muted text-center"
				:class="{
					'ml-4': ['xs', 'sm'].includes(windowWidth),
					'mr-4': ['xs', 'sm'].includes(windowWidth),
					'pl-4 pr-4': !['xs', 'sm'].includes(windowWidth),
				}"
				style="font-size: small;">
				{{ translate('menu_description') }}
			</p>
		</div>
		<div
			class="row no-gutters justify-content-center">
			<div
				:class="['xs', 'sm'].includes(windowWidth) ? '' : 'px-2'"
				class="col-12">
				<div
					v-for="(section, index) in sections.filter((sec) => Array.isArray(sec.valid_products) ? sec.valid_products.length > 0 : Object.keys(sec.valid_products).length > 0)"
					:key="index"
					class="border-secondary mb-2 px-2 pt-2 pb-0"
					style="border: 1px solid black; border-radius: 5px">
					<div
						class="d-flex justify-content-between pointer"
						@click="toggleCollapse(index)">
						<div class="d-flex align-items-center">
							<p class="mb-2">
								<b><i :class="`fas fa-chevron-${!collapse[index] ? 'down' : 'right'}`" /></b>
								<img
									v-if="section.valid_products && section.valid_products[0] && section.valid_products[0].image"
									style="width: 27px; height: 33px"
									:src="section.valid_products[0].image"
									class="h-100 ml-2">
								<b class="ml-2">{{ ['hardcover_book', 'ebook'].includes(section.code_name) ? translate(section.code_name) : (translate(section.valid_products[0].code_name) + (section.code_name.includes('free') ? translate('free_section') : '')) }}</b>
							</p>
						</div>
						<!-- <div
							v-if="section.type === 'product_list'"
							class="mr-3">
							<p
								class="mb-2"
								:class="(section.selected_qty || 0) !== section.qty ? 'text-danger' : 'text-discount'">
								<b>{{ (section.selected_qty || 0) }}/{{ section.qty }}</b>
							</p>
						</div> -->
					</div>
					<b-collapse :visible="!collapse[index]">
						<template v-if="section.type === 'product_list'">
							<div class="row mx-2 mt-1 mb-3">
								<div class="col-12">
									<div
										v-for="pIndex in section.qty"
										:key="pIndex">
										<div class="row">
											<label
												:for="`section${index}-${pIndex}`"
												class="form-label col-sm-3 col-form-label">
												{{ translate(section.selection_type + '_trans') }} #{{ pIndex }}
											</label>
											<div class="col-sm-9">
												<select
													class="form-control mb-3"
													@change="handleQtyChange(section, pIndex, $event)">
													<option
														:value="null"
														class="font-weight-light">
														{{ translate(section.selection_type + '_preferred') }}
													</option>
													<option
														v-for="(item, i) in section.valid_products[0].children"
														:key="i"
														:selected="sectionsSkus[section.code_name] && item.sku === sectionsSkus[section.code_name][pIndex]"
														:value="item.sku"
														class="font-weight-light">
														{{ ['boxes', 'items'].includes(section.code_name) ? translate(item.code_name) : getOnlyConfigurationName(translate(item.code_name)) }}
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
						<template v-else-if="section.type === 'dropdown'">
							<select
								v-model="selected[section.code_name]"
								:value="selected[section.code_name]"
								type="text"
								class="form-control mb-3">
								<option
									v-for="(product, sku) in section.valid_products"
									:key="sku"
									:value="sku">
									{{ translate(product.label) }}
								</option>
							</select>
						</template>
					</b-collapse>
				</div>
			</div>
		</div>
		<template v-slot:modal-footer>
			<div class="col col-12 no-gutters justify-content-end text-right mt-3">
				<b-button
					type="button"
					aria-label="Close"
					class="mr-2"
					@click="close()">
					{{ !!cancelTitle ? cancelTitle : translate('cancel') }}
				</b-button>
				<b-button
					type="button"
					variant="primary"
					aria-label="Close"
					:disabled="confirmDisabled"
					@click="confirm()">
					{{ !!okTitle ? okTitle : translate('confirm') }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';
import EventBus from '@/util/eventBus';
import { ExtraProductsModal, Products, Purchase } from '@/translations';
import UpgradePackageOption from './UpgradePackageOption';

export default {
	name: 'ExtraProductsModal',
	messages: [Products, ExtraProductsModal, Purchase],
	components: { UpgradePackageOption },
	mixins: [ConfigurableProducts, WindowSizes],
	props: {
		sectionsImport: {
			type: Array,
			default: () => [],
		},
		okTitle: {
			type: String,
			default: '',
		},
		cancelTitle: {
			type: String,
			default: '',
		},
		upgradeOptions: {
			type: Array,
			default: () => [],
		},
		packageCode: {
			type: String,
			default: '',
		},
		isFreeModal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			sections: [...this.sectionsImport],
			selected: {},
			sectionsSkus: {},
			disableConfirm: true,
			collapse: {},
		};
	},
	computed: {
		confirmDisabled() {
			return this.sections.filter((section) => ['product_list'].includes(section.type) && (section.selected_qty || 0) !== section.qty).length > 0;
		},
	},
	watch: {
		sectionsImport() {
			this.sections = [...this.sectionsImport];
			this.selected = {}; // Reset selected items
			this.sectionsSkus = {}; // Reset sections skus
			this.sections.forEach((section) => {
				section.selected_qty = 0; // Reset selected count
				if (section.type === 'dropdown' && typeof this.selected[section.code_name] === 'undefined') {
					this.selected = { ...this.selected, [section.code_name]: Object.keys(section.valid_products)[0] }; // Select the first item automatically
				} else if (section.type === 'product_list') {
					this.selected = { ...this.selected, [section.code_name]: { ...section.pre_selected ?? {} } }; // Initialize selected items
				}
			});
		},
		selected() {
			this.sections = this.sections.map((section) => ({
				...section,
				selected_qty: this.getQtyBySection(section), // Update selected count
			}));
			this.$emit('update:selectableProducts', this.selected);
		},
	},
	mounted() {
		EventBus.$on('showFreeProductsModal', () => {
			this.open();
		});
		EventBus.$on('hideFreeProductsModal', () => {
			this.close();
		});
	},
	beforeDestroy() {
		EventBus.$off('showFreeProductsModal');
		EventBus.$off('hideFreeProductsModal');
	},
	methods: {
		open() {
			this.$refs.extraProductsModal.show();
		},
		close() {
			EventBus.$emit('upgradePackageSelected', null);
			this.$refs.extraProductsModal.hide();
			this.$emit('close');
		},
		confirm() {
			this.$emit('update:selectableProducts', this.selected);
			this.$refs.extraProductsModal.hide();
			this.$emit('confirm');
		},
		handleQtyChange(section, index, event) {
			const sku = event.target.value;
			if (typeof this.selected[section.code_name] === 'undefined') {
				this.selected[section.code_name] = {};
			}

			if (typeof this.sectionsSkus[section.code_name] === 'undefined') {
				this.sectionsSkus[section.code_name] = {};
			}

			this.sectionsSkus[section.code_name][index] = sku;

			const sectionSkusQty = Object.values(this.sectionsSkus[section.code_name]).reduce((accum, currentSku) => {
				if (!currentSku) return accum;
				if (!accum[currentSku]) {
					accum[currentSku] = 1;
				} else {
					accum[currentSku] += 1;
				}
				return accum;
			}, {});

			this.selected[section.code_name] = { ...sectionSkusQty };
			section.selected_qty = this.getQtyBySection(section); // Update selected count
			this.sections = [...this.sections]; // Reassign object to detect change
		},
		getQtyBySection(section) {
			if (section.type === 'product_list') {
				return Object.values(this.selected[section.code_name] ?? {}).reduce((previousValue, currentValue) => previousValue + currentValue, 0);
			}
			return 0;
		},
		toggleCollapse(index) {
			if (this.collapse[index]) {
				this.collapse[index] = false;
			} else {
				this.collapse = { ...this.collapse, [index]: true };
			}
		},
	},
};
</script>
<style>
/* Custom b-modal sizes https://github.com/bootstrap-vue/bootstrap-vue/issues/632#issuecomment-441719709 */
.modal-lg-custom {
	max-width: 880px !important;
	width: 880px !important;
}
.modal-content {
	width: 96% !important;
}
</style>
